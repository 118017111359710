<template>
  <div class="levelAndStandardPage">
    <el-card>
      <div class="pageBody">
        <div class="leftTreeBox">
          <div class="titleBoxWrap">
            <h3>标准</h3>
            <div>
              <el-button size="small" type="primary" @click="addStandard()">新增标准</el-button>
            </div>
          </div>
          <div class="treeOutBox" v-loading="dataListLoading" :style="{ height: treeHeight }">
            <ul class="standardList">
              <li v-for="(node) in standardList" :key="node.id">
                <dl :class="{ 'standardNode': true, 'checked': standardId === node.id }" @click="changeData(node.id)">
                  <el-tooltip class="item" effect="dark" :content="node.viewName" placement="top">
                    <dt>{{ node.viewName }}</dt>
                  </el-tooltip>
                  <dd>
                    <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                      <i class="el-icon-edit" @click.stop="modifyStandard(node)"></i>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="删除" placement="top">
                      <i class="el-icon-delete" @click.stop="removeStandard(node.id)"></i>
                    </el-tooltip>
                  </dd>
                </dl>
              </li>
            </ul>
          </div>
        </div>
        <div class="rightTableBox">
          <div class="handleBoxWrap">
            <el-button size="small" type="primary" @click="addLevel()">新增等级</el-button>
          </div>
          <el-table v-loading="dataListLoading" ref="storeAreaTable" :data="dataList" border :height="tabHeight">
            <el-table-column v-for="row in headerList" :key="row.key" :prop="row.key" :min-width="row.width" :label="row.label" :show-overflow-tooltip="row.showOverflowTips" :formatter="row.formatter" header-align="center" align="center"></el-table-column>
            <el-table-column label="操作" fixed="right" header-align="center" align="center" width="160">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="modifyItem(scope.row)">编辑</el-button>
                <el-button type="text" size="small" @click="removeItem(scope.row.id)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :current-page.sync="curPage"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="limit"
            :total="total"
            @size-change="pageSizeChangeHandle"
            @current-change="pageCurrentChangeHandle">
          </el-pagination>
        </div>
      </div>
    </el-card>
    <level-edit ref="levelEdit" @reload="loadPage"></level-edit>
    <standard-edit ref="standardEdit" @reload="loadPage"></standard-edit>
  </div>
</template>
<script>
// import _ from 'lodash'
import standardEdit from './standard.edit'
import levelEdit from './level.edit'
import { getClientHeight } from '@/lib/utils'
export default {
  name: 'levelAndStandardPage',
  components: { standardEdit, levelEdit },
  data () {
    return {
      dataListLoading: false,
      dataList: [],
      standardList: [],
      standardId: null,
      curPage: 1,
      total: 0,
      limit: 20,
      headerList: [
        { label: '等级编码', key: 'code' },
        { label: '等级', key: 'name' },
        {
          label: '分值区间',
          key: 'begin',
          formatter: (row) => {
            let ret = [row.begin]
            if (row.beginFlag === '1') ret.push('≤')
            else ret.push('<')
            ret.push('x')
            if (row.endFlag === '1') ret.push('≤')
            else ret.push('<')
            ret.push(row.end)
            return ret.join(' ')
          }
        }
      ],
      tabHeight: '100%',
      treeHeight: '100%'
    }
  },
  mounted () {
    this.$nextTick(() => {
      // console.log(this.$utils.clientHeight() - 260 + 'px')
      this.tabHeight = getClientHeight() - 260 + 'px'
      this.treeHeight = getClientHeight() - 260 + 'px'
    })
  },
  activated () {
    this.loadPage()
  },
  methods: {
    loadPage () {
      this.dataListLoading = true
      this.getStandardList().then(() => {
        return this.getLevelPage()
      }).then(() => {
        this.dataListLoading = false
      }).catch((err) => {
        this.$message.error(err.message)
      })
    },
    getDataList () {
      this.dataListLoading = true
      this.getLevelPage().then(() => {
        this.dataListLoading = false
        this.$nextTick(() => {
          this.$refs.storeAreaTable.doLayout()
        })
      }).catch((err) => {
        this.$message.error(err.message)
      })
    },
    getStandardList () {
      return this.$http.get('/levelStandard/getAllList').then(({ data: res }) => {
        let { code, msg, data } = res
        if (code !== 0) {
          return Promise.reject(new Error(msg))
        } else {
          console.log(data)
          if (Array.isArray(data) && data.length) {
            this.standardList = data.map((v) => {
              return {
                ...v,
                viewName: `${v.name}（编码：${v.code}）`
              }
            })
            this.standardId = data[0].id
          }
          return Promise.resolve()
        }
      })
    },
    getLevelPage () {
      return this.$http.get('/level', {
        params: {
          page: this.curPage,
          limit: this.limit,
          levelStandardId: this.standardId
        }
      }).then(({ data: res }) => {
        let { code, msg, data } = res
        console.log(code, msg, data)
        if (code === 0 && data) {
          let { records, current, total } = data
          this.curPage = current * 1
          this.total = total * 1
          this.dataList = records && records.length ? records : []
        }
        if (code !== 0) {
          return Promise.reject(new Error(msg))
        }
        return Promise.resolve()
      })
    },
    // 切换每页数量
    pageSizeChangeHandle (size) {
      this.limit = size
      this.curPage = 1
      this.getDataList()
    },
    // 切换页
    pageCurrentChangeHandle (curPage) {
      this.curPage = curPage
      this.getDataList()
    },
    changeData (id) {
      if (this.standardId === id) return
      this.standardId = id
      this.getDataList()
    },
    addLevel () {
      this.$refs.levelEdit.init(this.standardId)
    },
    // 修改
    modifyItem (row) {
      console.log(row)
      this.$refs.levelEdit.init(this.standardId, row)
    },
    // 删除
    removeItem (id) {
      // console.log(arguments)
      this.$confirm('确定删除这个等级吗？', '分值等级管理').then(() => {
        return this.$http.delete(`/level/${id}`)
      }).then(({ data: res }) => {
        let { code, msg } = res
        console.log(code, msg)
        if (code !== 0) {
          this.$message.error(msg)
        }
        this.getDataList()
      })
    },
    addStandard () {
      this.$refs.standardEdit.init()
    },
    modifyStandard (item) {
      this.$refs.standardEdit.init(item)
    },
    removeStandard (id) {
      this.$confirm('确认删除这个标准？', '分值等级管理').then(() => {
        return this.$http.delete(`/levelStandard/${id}`)
      }).then(({ data: res }) => {
        let { code, msg } = res
        console.log(code, msg)
        if (code !== 0) {
          this.$message.error(msg)
        }
        this.loadPage()
      })
    }
  }
}
</script>
<style lang="scss">
.levelAndStandardPage {
  padding: 12px 20px 0;
  .fullWidth {
    width: 100%;
  }

  .titleBoxWrap {
    width: 100%;
    display: flex;
    align-items: stretch;
    align-content: space-between;
    > h3 {
      width: 50%;
      margin: 12px 0;
    }
    > div {
      width: 50%;
      text-align: right;
    }
  }

  .standardList {
    list-style: none;
    margin: 0;
    padding: 0;
    > li {
      margin: 0;
      padding: 0;
    }
  }

  .standardNode {
    flex: 1;
    width: 100%;
    margin: 0;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &.checked {
      background-color: #f5f7fa;
    }

    dt {
      max-width: calc(100% - 50px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      word-break: keep-all;
    }

    dd {
      margin: 0;
      text-align: right;
      width: 48px;
      i {
        width: 16px;
        height: 16px;
        font-size: 14px;
        cursor: pointer;
        margin-right: .5em;
      }
    }
  }

  .treeTitle {
    width: 100%;
    margin: 0;
    padding: 16px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    dt {
      width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: keep-all;
    }

    dd {
      width: 20%;
      text-align: right;
      i {
        width: 20px;
        height: 20px;
        font-size: 18px;
        cursor: pointer;

        & + i {
          margin-left: 10px;
        }
      }
    }
  }

  .treeOutBox {
    width: 100%;
    border: 1px solid #eee;
  }
  .pageBody {
    display: flex;
    width: 100%;
    align-items: stretch;
    justify-content: space-between;

    .leftTreeBox {
      width: 18%;
      margin-right: 15px;
    }

    .rightTableBox {
      width: 80%;
    }
  }
  .handleBoxWrap {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 10px;

    .el-select,.el-range-editor.el-input__inner,.el-input{
      margin: 0;
      margin-right: 10px;
      max-width: 200px;
    }
  }
}
</style>
